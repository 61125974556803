<template>
  <div>
    <div class="card mt-2">
      <div class="card-body">
        <h3 class="text-primary">Add Payment</h3>
        <hr />
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="name">Name<span class="text-danger">*</span> :</label>
              <input
                type="text"
                class="form-control"
                id="name"
                placeholder="type name.."
                v-model="name"
              />
            </div>
            <div class="form-group">
              <label for="base_currency"
                >Base Currency<span class="text-danger">*</span> :</label
              >
              <b-form-select
                class="form-control"
                id="base_currency"
                v-model="base_currency"
                :options="base_currency_option"
              >
              </b-form-select>
            </div>
            <div class="form-group">
              <label for="merchant_code">Merchant Code :</label>
              <input
                type="text"
                class="form-control"
                id="merchant_code"
                placeholder="type merchant code.."
                v-model="merchant_code"
                :disabled="group != 'e2pay'"
              />
              <small>**Only for e2pay group.</small>
            </div>
            <div class="form-group">
              <label for="android_version"
                >Android Lowest Supported Version :</label
              >
              <input
                type="text"
                class="form-control"
                id="android_version"
                placeholder="type android lowest supported version.."
                v-model="android_version"
              />
              <small>**Displayed payment in specific android version.</small>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="is_active"
                v-model="is_active"
              />
              <label class="form-check-label" for="is_active">
                Is Active
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="renewal_active"
                v-model="renewal_active"
              />
              <label class="form-check-label" for="renewal_active">
                Renewal Active
              </label>
              <small class="m-3"
                >**Activate auto renewal system for payment gateway.</small
              >
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="group">Group :</label>
              <b-form-select
                class="form-control"
                id="group"
                v-model="group"
                :options="group_option"
                @change="groupChange"
              >
              </b-form-select>
            </div>
            <div class="form-group">
              <label for="minimal_amount">Minimal Amount :</label>
              <input
                type="number"
                class="form-control"
                id="minimal_amount"
                placeholder="type minimal amount.."
                v-model="minimal_amount"
              />
            </div>
            <div class="form-group">
              <label for="merchant_code">Merchant Key :</label>
              <input
                type="text"
                class="form-control"
                id="merchant_key"
                placeholder="type merchant key.."
                v-model="merchant_key"
                :disabled="group != 'e2pay'"
              />
              <small>**Only for e2pay group.</small>
            </div>
            <div class="form-group">
              <label for="payment_logo">Payment Logo :</label>
              <input
                type="file"
                name="file"
                class="form-control-file"
                id="payment_logo"
                @change="onChange"
              />
            </div>
          </div>
          <button
            type="submit"
            class="btn btn-block btn-primary mt-5"
            @click="createPayment"
          >
            <i class="fa fa-pencil"></i>
            Create Payment
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from '../../plugins/http';
import constant from '../../store/constant';

const baseCurrencyOption = Object.entries(constant.BASE_CURRENCY).map(
  (val) => ({
    text: val[0],
    value: val[1],
  }),
);
const paymentGroupOption = Object.entries(constant.PAYMENT_GROUP).map(
  (val) => ({
    text: val[0].toLowerCase(),
    value: val[1],
  }),
);
export default {
  name: 'CreatePayment',
  data() {
    return {
      name: '',
      merchant_code: '',
      merchant_key: '',
      minimal_amount: 0,
      file: null,
      renewal_active: false,
      is_active: true,
      android_version: '',
      base_currency: 1,
      base_currency_option: [...baseCurrencyOption],
      group: 'midtrans',
      group_option: [...paymentGroupOption],
    };
  },
  methods: {
    groupChange() {
      if (this.group != 'e2pay') {
        this.merchant_code = '';
        this.merchant_key = '';
      }
    },
    onChange(e) {
      this.file = e.target.files[0];
    },
    async createPayment() {
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('name', this.name);
      formData.append('baseCurrency', this.base_currency);
      formData.append('merchantCode', this.merchant_code);
      formData.append('merchantKey', this.merchant_key);
      formData.append('androidVersion', this.android_version);
      formData.append('group', this.group);
      formData.append('minimalAmount', this.minimal_amount);
      formData.append('isActive', this.is_active);
      formData.append('renewalActive', this.renewal_active);

      try {
        await API.post('/payments-gateways', formData)
          .then((res) => {
            if (res.status === 201) {
              this.$swal({
                icon: 'success',
                toast: true,
                position: 'top-end',
                title: 'Payment Has Been Successfully Created',
                timer: 10000,
                text: this.message,
              });
              this.$router.push('/settings/payments');
            }
          })
          .catch((error) => {
            this.$swal({
              icon: 'error',
              toast: true,
              position: 'top-end',
              title:
                error.response.status === 422
                  ? error.response.data.message
                  : error.response.data.data.message,
              timer: 10000,
              text: this.message,
            });
          });
      } catch (error) {
        console.log(error.response);
        this.$swal({
          icon: 'error',
          toast: true,
          position: 'top-end',
          title: 'Failed To Create New Payment',
          timer: 5000,
          text: this.message,
        });
      }
    },
  },
};
</script>
