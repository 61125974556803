var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "card mt-2"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h3', {
    staticClass: "text-primary"
  }, [_vm._v("Add Payment")]), _c('hr'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_vm._m(0), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.name,
      expression: "name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "name",
      "placeholder": "type name.."
    },
    domProps: {
      "value": _vm.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.name = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_vm._m(1), _c('b-form-select', {
    staticClass: "form-control",
    attrs: {
      "id": "base_currency",
      "options": _vm.base_currency_option
    },
    model: {
      value: _vm.base_currency,
      callback: function ($$v) {
        _vm.base_currency = $$v;
      },
      expression: "base_currency"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "merchant_code"
    }
  }, [_vm._v("Merchant Code :")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.merchant_code,
      expression: "merchant_code"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "merchant_code",
      "placeholder": "type merchant code..",
      "disabled": _vm.group != 'e2pay'
    },
    domProps: {
      "value": _vm.merchant_code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.merchant_code = $event.target.value;
      }
    }
  }), _c('small', [_vm._v("**Only for e2pay group.")])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "android_version"
    }
  }, [_vm._v("Android Lowest Supported Version :")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.android_version,
      expression: "android_version"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "android_version",
      "placeholder": "type android lowest supported version.."
    },
    domProps: {
      "value": _vm.android_version
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.android_version = $event.target.value;
      }
    }
  }), _c('small', [_vm._v("**Displayed payment in specific android version.")])]), _c('div', {
    staticClass: "form-check"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.is_active,
      expression: "is_active"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "checkbox",
      "value": "",
      "id": "is_active"
    },
    domProps: {
      "checked": Array.isArray(_vm.is_active) ? _vm._i(_vm.is_active, "") > -1 : _vm.is_active
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.is_active,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.is_active = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.is_active = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.is_active = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "form-check-label",
    attrs: {
      "for": "is_active"
    }
  }, [_vm._v(" Is Active ")])]), _c('div', {
    staticClass: "form-check"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.renewal_active,
      expression: "renewal_active"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "checkbox",
      "value": "",
      "id": "renewal_active"
    },
    domProps: {
      "checked": Array.isArray(_vm.renewal_active) ? _vm._i(_vm.renewal_active, "") > -1 : _vm.renewal_active
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.renewal_active,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.renewal_active = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.renewal_active = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.renewal_active = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "form-check-label",
    attrs: {
      "for": "renewal_active"
    }
  }, [_vm._v(" Renewal Active ")]), _c('small', {
    staticClass: "m-3"
  }, [_vm._v("**Activate auto renewal system for payment gateway.")])])]), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "group"
    }
  }, [_vm._v("Group :")]), _c('b-form-select', {
    staticClass: "form-control",
    attrs: {
      "id": "group",
      "options": _vm.group_option
    },
    on: {
      "change": _vm.groupChange
    },
    model: {
      value: _vm.group,
      callback: function ($$v) {
        _vm.group = $$v;
      },
      expression: "group"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "minimal_amount"
    }
  }, [_vm._v("Minimal Amount :")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.minimal_amount,
      expression: "minimal_amount"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number",
      "id": "minimal_amount",
      "placeholder": "type minimal amount.."
    },
    domProps: {
      "value": _vm.minimal_amount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.minimal_amount = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "merchant_code"
    }
  }, [_vm._v("Merchant Key :")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.merchant_key,
      expression: "merchant_key"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "merchant_key",
      "placeholder": "type merchant key..",
      "disabled": _vm.group != 'e2pay'
    },
    domProps: {
      "value": _vm.merchant_key
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.merchant_key = $event.target.value;
      }
    }
  }), _c('small', [_vm._v("**Only for e2pay group.")])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "payment_logo"
    }
  }, [_vm._v("Payment Logo :")]), _c('input', {
    staticClass: "form-control-file",
    attrs: {
      "type": "file",
      "name": "file",
      "id": "payment_logo"
    },
    on: {
      "change": _vm.onChange
    }
  })])]), _c('button', {
    staticClass: "btn btn-block btn-primary mt-5",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.createPayment
    }
  }, [_c('i', {
    staticClass: "fa fa-pencil"
  }), _vm._v(" Create Payment ")])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    attrs: {
      "for": "name"
    }
  }, [_vm._v("Name"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v(" :")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    attrs: {
      "for": "base_currency"
    }
  }, [_vm._v("Base Currency"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v(" :")]);
}]

export { render, staticRenderFns }